import React, { useState, useEffect } from 'react';
import { useThread } from './ThreadContext';
import ChatContainer from './ChatContainer';
import ChatInput from './ChatInput';

interface SearchEvent {
    completed: boolean;
    results: string[];
    search_message: string;
}

// const BASE_URL = "http://localhost:7071";
//const DEV_BASE_URL = 'https://reprompt--soltop-api-fastapi-app-dev.modal.run';
const PROD_BASE_URL = 'https://reprompt--soltop-api-fastapi-app.modal.run'

const BASE_URL = PROD_BASE_URL;

const ChatComponent: React.FC = () => {
    const { threadId, setThreadId } = useThread();
    const [messages, setMessages] = useState<{ id: string, role: string, content: string, searchEvent: SearchEvent | null }[]>([]);
    const [input, setInput] = useState<string>('');
    const [currentMessage, setCurrentMessage] = useState<string>('');
    const [currentSearchEvent, setCurrentSearchEvent] = useState<SearchEvent | null>(null);
    const [showDealerForm, setShowDealerForm] = useState(false);
    const [isSending, setIsSending] = useState<boolean>(false);

    useEffect(() => {
        if (!threadId) {
            createThread();
        }
    }, [threadId]);

    const createThread = async () => {
        const response = await fetch(BASE_URL + '/api/create-thread', { method: 'POST' });
        const data = await response.json();
        setThreadId(data.threadId);
    };

    const sendMessage = async () => {
        if (!input.trim()) return;

        setIsSending(true);
        const newMessage = { id: `${Date.now()}`, role: 'user', content: input, searchEvent: null };
        setMessages([...messages, newMessage]);
        setInput('');

        const response = await fetch(BASE_URL + '/api/chat', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ message: input, threadId }),
        });

        const reader = response.body?.getReader();
        const decoder = new TextDecoder();
        let done = false;
        let buffer = '';
        let accumulatedText = '';

        while (!done) {
            const { value, done: doneReading } = await reader?.read()!;
            done = doneReading;
            const chunk = decoder.decode(value, { stream: true });
            buffer += chunk;

            const lines = buffer.split('\n');
            buffer = lines.pop()!; // Keep the last partial line in the buffer

            for (const line of lines) {
                if (line.trim()) {
                    const parsed = JSON.parse(line);
                    if (parsed.type === 'text_delta') {
                        accumulatedText += parsed.data;
                        setCurrentMessage(accumulatedText);
                    } else if (parsed.type === 'search_started') {
                        setCurrentSearchEvent(parsed.data);
                    } else if (parsed.type === 'search_completed') {
                        setCurrentSearchEvent(parsed.data);
                    } else if (parsed.type === 'propose_user_meets_dealer') {
                        setShowDealerForm(true);
                    }
                }
            }

            if (done) {
                setMessages((prevMessages) => [
                    ...prevMessages,
                    { id: `${Date.now()}`, role: 'assistant', content: accumulatedText, searchEvent: currentSearchEvent },
                ]);
                setCurrentMessage('');
                setCurrentSearchEvent(null);
                setIsSending(false);
            }
        }
    };

    return (
        <div className="fixed inset-0 sm:inset-auto sm:bottom-16 sm:top-16 sm:absolute sm:right-0 sm:mr-4 bg-white p-6 sm:rounded-lg sm:border sm:border-gray-200 flex flex-col sm:w-[35rem] lg:w-[40rem] sm:max-w-full sm:h-auto sm:shadow-md ">
            <div className="flex-none flex items-center justify-between">
                <div className="flex justify-between items-center w-full">
                    <div className="flex items-center p-4 bg-white rounded-lg">
                        <img src="https://www.soltop-energie.ch/cm/wp-content/uploads/soltopenergie-logo-pos.svg" alt="Logo" className="h-8 mr-2" />
                        <h1 className="text-xl font-semibold">Chat</h1>
                    </div>
                </div>
            </div>
            {threadId ? (
                <>
                    <ChatContainer 
                        messages={messages} 
                        currentMessage={currentMessage} 
                        currentSearchEvent={currentSearchEvent} 
                        addMessage={(message) => setMessages((prevMessages) => [...prevMessages, message])} 
                        setInput={setInput} 
                        sendMessage={sendMessage} 
                        showDealerForm={showDealerForm}
                    />
                    <ChatInput input={input} setInput={setInput} sendMessage={sendMessage} isSending={isSending} />
                </>
            ) : (
                <div className="flex justify-center items-center h-full">
                    <p className="text-gray-500">Creating chat session...</p>
                </div>
            )}
        </div>
    );
};

export default ChatComponent;