import React from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { UserIcon, ChatBubbleLeftRightIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';

interface SearchEvent {
    completed: boolean;
    results: string[];
    search_message: string;
}

interface ChatMessageProps {
    role: 'user' | 'assistant';
    content: string;
    searchEvent?: SearchEvent | null;
}

const ChatMessage: React.FC<ChatMessageProps> = ({ role, content, searchEvent }) => {
    const isUser = role === 'user';
    return (
        <div className={`flex gap-3 my-4 text-gray-600 text-sm ${isUser ? 'justify-end' : 'justify-start'}`}>
            <span className="relative flex shrink-0 rounded-full w-8 h-8">
                <div className="rounded-full bg-gray-100 border p-1">
                    {isUser ? (
                        <UserIcon className="h-6 w-6 text-black" />
                    ) : (
                        <ChatBubbleLeftRightIcon className="h-6 w-6 text-black" />
                    )}
                </div>
            </span>
            <div className="leading-relaxed">
                <span className="block font-bold text-gray-700 text-xs">{isUser ? 'You' : 'AI'}</span>
                {searchEvent && (
                    <div>
                    <p className="juice:first:mt-0 my-1.5 relative h-[26px] juice:h-8 text-token-text-secondary hover:text-token-text-primary">
                        <button className="group absolute left-0 top-0 mr-1.5 h-[26px] overflow-hidden juice:h-8 bg-gray-200 text-gray-700 rounded-md px-2 py-1 hover:bg-gray-300 transition-colors duration-200" style={{ opacity: 1 }}>
                            <div className={`flex items-center justify-start gap-1 ${!searchEvent.completed && 'animate-pulse'}`}>
                                <div className="rounded-full bg-white p-1">
                                    <MagnifyingGlassIcon className="h-4 w-4 text-gray-500" />
                                </div> 
                                <span>{searchEvent.search_message}</span>
                            </div>
                        </button>
                    </p>
                    <hr className="my-2 border-gray-300" />
                    </div>
                )}
                <div className="prose text-sm text-gray-500">
                    <Markdown
                  remarkPlugins={[remarkGfm]}
                  rehypePlugins={[rehypeRaw]}
                  components={{
                    a({ href, children, className, ...props }) {
                      return <a href={href} target="_blank" rel="noopener noreferrer" className={`break-all ${className}`} {...props}>{children}</a>;
                    }
                  }}
                >
                  {content}
                </Markdown>
                </div>
            </div>
        </div>
    );
};

export default ChatMessage;