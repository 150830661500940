import React, { useRef, useEffect, useState } from 'react';
import ChatMessage from './ChatMessage';
import { LightBulbIcon, ArrowsRightLeftIcon, AcademicCapIcon, QuestionMarkCircleIcon, WrenchScrewdriverIcon } from '@heroicons/react/24/outline';

interface SearchEvent {
    completed: boolean;
    results: string[];
    search_message: string;
}

interface ChatContainerProps {
    messages: { id: string, role: string, content: string, searchEvent: SearchEvent | null}[];
    currentMessage: string;
    currentSearchEvent: SearchEvent | null;
    addMessage: (message: { id: string, role: string, content: string, searchEvent: SearchEvent | null }) => void;
    setInput: (input: string) => void;
    sendMessage: () => void;
    showDealerForm: boolean;
}

const ChatContainer: React.FC<ChatContainerProps> = ({ messages, currentMessage, currentSearchEvent, addMessage, setInput, sendMessage, showDealerForm }) => {
    const chatWindowRef = useRef<HTMLDivElement>(null);
    const [hasInteracted, setHasInteracted] = useState<boolean>(false);

    useEffect(() => {
        if (chatWindowRef.current) {
            chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
        }
    }, [messages, currentMessage, currentSearchEvent]);
    const predefinedMessages = [
        { title: "AP420 W-Lan", content: "Hat die AP420 W-Lan?", icon: WrenchScrewdriverIcon, iconColor: 'text-blue-700' },
        { title: "Luftwärmepumpen", content: "Kann ich bei Luftwärmepumpen den Vortex-Sensor verändern in der Software?", icon: WrenchScrewdriverIcon, iconColor: 'text-green-700' },
        //{ title: "Technische Frage", content: "Wie funktioniert die Wärmepumpensteuerung?", icon: CpuChipIcon, iconColor: 'text-red-700' },
        //{ title: "Support Anfrage", content: "Ich benötige Hilfe bei der Installation meiner Solaranlage.", icon: LifebuoyIcon, iconColor: 'text-yellow-700' },
    ];

    const handlePredefinedMessageClick = (content: string) => {
        setInput(content);
        sendMessage();
        setHasInteracted(true);
    };

    return (
        <div className="overflow-auto ">
            <div className="pr-4" ref={chatWindowRef}>
                {messages.map((msg, index) => (
                    <ChatMessage key={index} role={msg.role as 'user' | 'assistant'} content={msg.content} searchEvent={msg.searchEvent} />
                ))}
                {currentMessage && (
                    <ChatMessage role="assistant" content={currentMessage} searchEvent={currentSearchEvent} />
                )}
            </div>
            {!hasInteracted && messages.length === 0 && (
                <div className="flex flex-wrap items-stretch justify-center gap-4 p-4">
                    {predefinedMessages.map((msg, index) => (
                        <button
                            key={index}
                            className="relative flex w-40 flex-col gap-2 rounded-2xl border border-gray-300 px-3 pb-4 pt-3 text-start align-top text-sm shadow-xxs transition enabled:hover:bg-gray-100 disabled:cursor-not-allowed"
                            onClick={() => handlePredefinedMessageClick(msg.content)}
                        >
                            <msg.icon className={`h-5 w-5 ${msg.iconColor}`} />
                            <div className="line-clamp-3 text-gray-600 break-word">{msg.title}</div>
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ChatContainer;