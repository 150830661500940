'use client';
import React, { createContext, useState, useContext, ReactNode } from 'react';

interface ThreadContextType {
    threadId: string | null;
    setThreadId: (id: string) => void;
}

const ThreadContext = createContext<ThreadContextType | undefined>(undefined);

export const ThreadProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [threadId, setThreadId] = useState<string | null>(null);

    return (
        <ThreadContext.Provider value={{ threadId, setThreadId }}>
            {children}
        </ThreadContext.Provider>
    );
};

export const useThread = (): ThreadContextType => {
    const context = useContext(ThreadContext);
    if (!context) {
        throw new Error('useThread must be used within a ThreadProvider');
    }
    return context;
};